<template>
  <el-cascader
    v-show="reset"
    ref="cascader"
    v-model="val"
    @change="change"
    :props="getProps()"
    style="width: 100%"
    :separator="separator"
  ></el-cascader>
</template>

<script>
import { api } from '/src/api/base';
const getDistrict = api('app')('get.district.json');
export default {
  name: 'lgb-cascader',
  props: {
    value: String,
    level: String,
    separator: {
      default: '/',
      type: String
    }
  },
  data () {
    return {
      reset: true,
      val: [],
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          getDistrict({
            $ignoreRepeat: true,
            keywords: node.data && (node.data.code || node.data.value)
          }).then(res => {
            res = res.map(it => ({
              code: it.code,
              value: it.name,
              label: it.name,
              leaf: it.level === this.level || it.level === 'district'
            }));
            resolve(res);
          });
        }
      }
    };
  },
  watch: {
    value: function (val, old) {
      if (val === old) return;
      this.reset = false;
      this.val = val.split(',');
      setTimeout(() => {
        this.reset = true;
      }, 10);
    }
  },
  methods: {
    change (val) {
      this.$emit('input', val.join(','));
      try {
        this.$parent.clearValidate();

      } catch (error) {
        console.log(111);
      }
    },
    getProps () {
      let that = this;
      return {
        lazy: true,
        lazyLoad (node, resolve) {
          getDistrict({
            $ignoreRepeat: true,
            keywords: node.data && (node.data.code || node.data.value)
          }).then(res => {
            res = res.map(it => ({
              code: it.code,
              value: it.name,
              label: it.name,
              leaf: it.level === that.level || it.level === 'district'
            }));
            resolve(res);
          });
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.file-upload > input {
  display: none;
}
</style>
